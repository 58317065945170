<template>
  <v-card>
    <v-card-title>
      <span class="headline">Neues Unternehmen anlegen</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="newOrganisationForm" v-model="newOrganisationForm" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                class="pt-6"
                v-model="newOrganisationFormData.name"
                label="Name"
                :rules="[() => !!newOrganisationFormData.name || 'Dieses Feld wird benötigt!']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col>
              <v-text-field
                v-model="newOrganisationFormData.testSiteNumber"
                label="Teststellennummer"
                :rules="[() => !!newOrganisationFormData.testSiteNumber || 'Dieses Feld wird benötigt!']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col sm="8">
              <v-text-field
                v-model="newOrganisationFormData.streetName"
                :rules="[() => !!newOrganisationFormData.streetName || 'Dieses Feld wird benötigt!']"
                required
                label="Straße"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newOrganisationFormData.houseNumber"
                :rules="[() => !!newOrganisationFormData.houseNumber || 'Dieses Feld wird benötigt!']"
                required
                label="Hausnummer"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col>
              <v-text-field
                v-model="newOrganisationFormData.zipCode"
                :rules="[() => !!newOrganisationFormData.zipCode || 'Dieses Feld wird benötigt!']"
                required
                label="Postleitzahl"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newOrganisationFormData.cityName"
                :rules="[() => !!newOrganisationFormData.cityName || 'Dieses Feld wird benötigt!']"
                required
                label="Stadt"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col
              ><v-text-field
                v-model="newOrganisationFormData.mailHealthDepartment"
                label="E-Mail Adresse Gesundheitsamt"
                :rules="[() => !!newOrganisationFormData.mailHealthDepartment || 'Dieses Feld wird benötigt!']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col>
              <v-text-field
                v-model="newOrganisationFormData.mailSelectedMedic"
                label="E-Mail Adresse Unternehmenszuständiger"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt">
            <v-col>
              <v-checkbox
                v-model="newOrganisationFormData.selfManaged"
                label="Selbst organisiertes Unternehmen"
                :disabled="newOrganisationFormData.testPoint"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col>
              <v-checkbox
                v-model="newOrganisationFormData.testPoint"
                label="Corona Testpoint"
                :disabled="newOrganisationFormData.selfManaged"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="saveOrganisation()">
        Anlegen
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      newOrganisationForm: false,
      newOrganisationFormData: {},
      organisationLoading: false,
      snackbar: false,
      snackbarText: "",
    };
  },

  methods: {
    saveOrganisation() {
      if (this.$refs.newOrganisationForm.validate()) {
        this.$http({
          method: "post",
          url: "/organisation",
          data: {
            name: this.newOrganisationFormData.name,
            streetName: this.newOrganisationFormData.streetName,
            cityName: this.newOrganisationFormData.cityName,
            houseNumber: this.newOrganisationFormData.houseNumber,
            zipCode: this.newOrganisationFormData.zipCode,
            mailHealthDepartment: this.newOrganisationFormData.mailHealthDepartment,
            mailSelectedMedic: this.newOrganisationFormData.mailSelectedMedic,
            selfManaged: this.newOrganisationFormData.selfManaged,
            testSiteNumber: this.newOrganisationFormData.testSiteNumber,
            testPoint: this.newOrganisationFormData.testPoint,
          },
        })
          .then((res) => {
            this.$emit("neworganisation", res.data);
            this.$refs.newOrganisationForm.reset();
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.snackbarText = "Es ist ein Fehler bei der Abfrage aufgetreten";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
