<template>
  <v-container>
    <div class="d-flex justify-space-between my-5">
      <div class="v-text-field text-h4">Firmenverwaltung</div>
    </div>
    <v-card>
      <v-card-title>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Neues Unternehmen
            </v-btn>
          </template>
          <new-organisation v-on:neworganisation="organisationCreated"></new-organisation>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organisations"
        :items-per-page="15"
        :search="search"
        :loading="loading"
        sort-by="id"
        :sort-asc="true"
        class="elevation-1"
      >
        <template v-slot:item.address="{ item }">
          {{ getAddress(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editOrganisation(item)" color="primary">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteOrganisation(item)" color="red">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline"><div>Möchtest du dieses Unternehmen löschen?</div></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteOrganisationConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import NewOrganisation from "../components/admin_organisation/New.vue";

export default {
  components: {
    NewOrganisation,
  },
  data() {
    return {
      search: "",
      loading: false,
      errored: false,
      dialog: false,
      dialogDelete: false,
      snackbar: false,
      snackbarText: "",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Name", value: "name" },
        { text: "Adresse", value: "address" },
        { text: "Durchgeführte Tests", value: "testCount" },
        { text: "Testpoint", value: "testPoint" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      organisations: [],
      organisationsIndex: 0,
    };
  },

  mounted() {
    this.loading = true;
    this.$http({
      method: "get",
      url: "/organisation",
      params: {},
    })
      .then((response) => {
        this.organisations = response.data;
        this.loading = false;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getAddress(item) {
      return item.streetName + " " + item.houseNumber + " , " + item.zipCode + " " + item.cityName;
    },

    organisationCreated(item) {
      this.organisations.push(item);
      this.snackbarText = "Unternehmen erfolgreich angelegt";
      this.snackbar = true;
      this.dialog = false;
    },

    editOrganisation(item) {
      this.$router.push("/admin/organisation/" + item.id);
    },

    deleteOrganisation(item) {
      this.organisationsIndex = this.organisations.indexOf(item);
      this.organisationDelete = item;
      this.dialogDelete = true;
    },

    deleteOrganisationConfirm() {
      this.$http({
        method: "delete",
        url: "/organisation",
        data: {
          organisationId: this.organisationDelete.id,
        },
      })
        .then(() => {
          this.snackbarText = "Unternehmen erfolgreich gelöscht";
          this.snackbar = true;
          this.organisations.splice(this.organisationsIndex, 1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });
      this.dialogDelete = false;
    },
  },
};
</script>
